#news-form-all-bundle .dn_rating .field-name-field-gin-text-rating select, #news-form-all-bundle .dn_rating .field-name-field-spotter-text-rating select {}// Sass variable
$grey: #505050;
$dark_grey: #282828;
$icons: 'Glyphicons Halflings';


// General style
@media only screen and (max-width: 768px) {
  .xs_center {
    text-align: center !important; } }

.ajax-progress {
  margin-right: 5px;
  margin-left: 5px; }

.no_padding {
  padding: 0 !important; }
.no_l_padding {
  padding-left: 0 !important; }
.upper {
  text-transform: uppercase; }
.bold {
  font-weight: bold; }
.gray {
  color: #A0A0A0;
  &:hover {
    color: #A0A0A0; } }

button, a, a:hover, li:hover, input[type="submit"], input[type="submit"]:hover {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  &:focus {
    text-decoration: none; } }

/*
 * Header
 * ************************************************************************************************ */
#navbar {
  background-color: white;
  border: none; }

/* top menu */
.region-top-bar {
  text-align: center;

  li {
    display: inline-block;
    a {
      padding: 20px 8px;
      margin-right: -4px; // fix unknown space between menu links
      font-weight: bold;
      line-height: 21px;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: white;
      &:hover,
      &:focus,
      &:active {
        background: #8c8c8c;
        color: white; } } }
  li.active {
    a {
      background: #8c8c8c;
      color: white; } }
  li.open {
    a {
      background: #8c8c8c;
      &:hover,
      &:focus,
      &:active {
        background: #8c8c8c;
        color: white; } } }
  .dropdown-menu {
    background: #505050;
    border-radius: 0px;
    margin: 0;
    border: none;
    padding-top: 0px;
    padding-bottom: 0px;
    li {
      width: 100%;
      a {
        background: #505050;
        margin-right: 0;
        &:hover {
          background: #8c8c8c; } } } } }

// btn style
.btn {
  position: relative;
  background: $dark_grey;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  &:hover,
  &:focus,
  &:active {
    color: white;
    background: #C8C8C8; }
  &:before {
    font-family: $icons;
    position: absolute;
    left: 13px;
    top: 5px; }
  span {
    display: none; } }

// Buttons style Add, Delete, Save...
// .btn-success
//   &:before
//     content: "\002b"

// .form-submit
//   &:before
//     content: "\e013"

// .button--danger
//   &:before
//     content: "\e020"

// .button--edit
//   &:before
//     content: "\270f"

// .button--cancel
//   &:before
//     content: "\e014"

// .button_search
//   &:before
//     content: "\e003" !important

// .button_export
//   &:before
//     content: "\e066" !important

// #edit-reset
//   &:before
//     content: "\e031"

/*
 * Background style
 * ************************************************************************************************ */
.bg-white {
  background: white; }

.bg-gray {
  background-color: $grey; }

/*
 * Login pages
 * ************************************************************************************************ */

/* *** Login Block *** */
// Also make redirect (301) from '#/user' page to '#/'
#user-pass, // front page: #/
#user-login { // #/user/password
  .form-item {
    display: table;
    width: 100%;
    label {
      display: table-cell;
      vertical-align: middle;
      width: 50%;
      padding-left: 0;
      text-transform: uppercase;
      input {
        width: 100%;
        margin: 0; } } }
  .req_pass {
    display: inline-block;
    clear: both;
    padding-left: 0;
    list-style-type: none;
    &:hover {
      color: #094c83; } }
  #edit-actions {
    display: inline-block;
    width: 50%;
    float: right;
    #edit-submit {
      width: 100%; } }
  .remember_inp {
    display: inline-block;
    width: 50%;
    clear: both;
    float: right;
    input {
      position: relative;
      top: 2px;
      margin-right: 10px; } }
  .fr_text {
    margin-bottom: 30px; } }

/* ########## Form Fields  ########## */
.form-item_table {
  display: table;
  width: 100%;
  label {
    display: table-cell;
    vertical-align: middle;
    // width: 50%
    padding-left: 0;
    text-transform: uppercase;
    input {
      width: 100%;
      margin: 0; } } }
/*
 * Daily News Form Tmp
 * ************************************************************************************************ */

// Dailt 'form-item' style
// Add
#news-form-all-bundle {}
//  .field-widget-number,
//  .field-widget-text-textarea,
//  .field-widget-date-text
//    .form-item
//      @extend .form-item_table
//      label
//        width: 195px
//// yes / no
//.dn_approved_status
//  .field-widget-options-buttons
//    .form-type-radios
//      width: 100%
//      margin-right: 0
//    label
//      display: none
//    .form-radios
//      .form-item
//        width: 50%
//        display: inline-block
//        margin-top: 5px
//        margin-bottom: 0
//        margin-right: 0
//      .radio
//        label
//          display: block
//          text-transform: uppercase
//// media type
//.dn_media_type
//  .form-item
//    .form-radios,
//    .form-checkboxes // need for 'source media type' filed
//      display: inline-block
//      .form-item
//        display: inline-block
//        margin: 0
//        .control-label
//          padding-left: 9px
//          display: block
//          width: auto
//          input
//            display: none
//        &:first-child
//          .control-label
//            padding-left: 0px
//// text + summary
//.dn_summary
//  .text-summary-wrapper
//    textarea
//      height: 36px
//    .grippie
//      display: none
//.dn_text
//  .field-widget-text-textarea-with-summary
//    label
//      vertical-align: top
//    .grippie
//      display: none
//// Notes
//.dn_notes
//  .field-widget-text-textarea
//    label
//      vertical-align: top
//  .grippie
//      display: none
//.sportter
//  padding-top: 15px
//  padding-bottom: 20px
//  .sportter_label
//    padding-top: 10px
//    label
//      display: none !important
//    .completed_check
//      position: absolute
//      left: -1px
//      top: 10px
//      label
//        display: block !important
//  .sportter_list
//    .col-sm-3
//      width: 20%
//    label
//      display: none !important
//    .completed_check
//      position: absolute
//      right: -10px
//      top: 9px
//      label
//        display: block !important
//// Spotter Details
//.spotter_title_det
//  margin-top: 10px
//.spotter_det
//  .field-widget-text-textfield,
//  .field-widget-number
//    .form-item
//      label
//        display: none
//// Spotter Loc
//.spotter_title
//  font-size: 12px
//  color: #A1A1A1
//  padding-top: 10px
//  padding-bottom: 20px
//  margin-left: 15px;

//.date_warning
//  padding-top: 10px
//  color: red
//// Bottom btns
//.dn_btns
//  .btn
//    margin-top: 10px
//    width: 175px
//// hide 'Add to flag' checkbox
//.field-name-field-daily-add-to-flag
//  display: none

//.field-name-field-total-gin-rating
//  display: none

section.block-news {
  display: inline-block;
  float: right;
  margin-right: 15px;
  &:last-child {
    margin-right: 0; }
  a {
    width: 175px; } }

// Edit
.page-news-daily-edit {
  #news-form-all-bundle {
    .tmp_btns {
      display: none; } } }

/*
 * Freelance News Form Tmp
 * ************************************************************************************************ */
// Add
.page-news-freelance {
  #news-form-all-bundle {
    .tmp_btns {
      display: none; } } }

// Edit
.page-news-freelance-edit {
  #news-form-all-bundle {
    .field_created_date {
      padding-top: 0;
      margin-bottom: 15px; } } }

/*
 * EXCLUSIVE News Form Tmp
 * ************************************************************************************************ */
// Add + Edit
.page-news-exclusive {
 .dn_add_exc_news {
   display: none; } }


/*
 * Freelance Login Page #/
 * ************************************************************************************************ */
.fr_text {
  font-weight: bold;
  text-transform: uppercase; }
.fr_form {
  margin-top: 20px;
  input {
    position: relative;
    top: 2px;
    margin-right: 10px; } }
.bottom_row {
  margin-top: 20px;
  margin-bottom: 20px;
  .fr_text {
    margin-top: 9px; }
  input {
    width: 100%;
    height: 36px; }
  .text_inp {
    border: 1px solid #e0e0e0;
    padding-left: 10px;
    ::-webkit-input-placeholder {
      color:#c0392b {} }
    ::-moz-placeholder {
      color:#c0392b {} }
    -moz-placeholder: {
      color:#c0392b {} }
    -ms-input-placeholder: {
      color:#c0392b {} } } }

/*
 * View
 * ************************************************************************************************ */

/* filter (general)*/
.view {
  .btn {
    min-width: 175px; } }

.view-header {
  p {
    display: inline-block; }
  p.v_header_item {
    color: #A1A1A1;
    text-transform: uppercase;
    font-weight: bold;
    float: left; }
  margin-bottom: 15px;
  text-align: right; }

.views-exposed-form {
  .views-exposed-widgets {
    margin-bottom: 0; }
  .buttons-panel {
    clear: both;
    float: right;
    margin-top: 50px;
    margin-bottom: 15px;
    .views-exposed-widget {
      padding-top: 0;
      &:last-child {
        padding-right: 0; }
      .btn {
        margin-top: 0; } } }
  .container-inline-date {
    .form-item {
      width: 100%;
      margin-right: 0;
      input {
        width: 100%;
        margin-right: 0; } } } }

#edit-date-filter-1-wrapper {
  margin-right: 0; }

#edit-nid-created-wrapper {
  label.control-label {
    display: none; } }
#edit-nid-created-1-wrapper {
  @extend #edit-date-filter-1-wrapper;
  label.control-label {
    display: none; } }

/* View - freelanse news */
.view-freelance-news,
.view-freelancer-freelance-news {
  table.views-table {
    tr {
      .views-field-title {
        width: 620px; }
      .views-field-field-text {
        width: 600px; } } }

  .view-filters_links {
    &:first-child {
      font-family: "FontAwesome";
      content: "\xf069"; } }

  .filter_links {
      display: inline-block;
      text-transform: uppercase;
      color: #282828;
      white-space: nowrap;
      padding: 0 12px; }

  .filter_links:hover {
    color: #282828;
    background: #eaeaea; }

  #edit-field-freelance-state-value-wrapper {
    display: none; } }

/*
 * Table (view)
 * ************************************************************************************************ */
//.table-responsive
//  .tabledrag-toggle-weight-wrapper,
//  .ief-tabledrag-header
//   display: none

.td-action-link {
  width: 25px;
  a {
    position: relative;
    display: block;
    width: 25px;
    height: 25px;
    color: #505050;
    &:hover {
      color: white;
      background: #505050; }
    span.glyphicon {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); } } }

/* *** FR news + DL news + EX news *** */
// hide drag elements
.ief-sort-order-header,
.tabledrag-hide,
//.ief-tabledrag-handle,
//.ief-tabledrag-header
//  display: none

// image source
.field-name-field-inline-pictures-source {
  td.inline-entity-form-gin_pic_news_pic-field_img_news_original,
  td.inline-entity-form-gin_pic_news_pic-field_img_newsretoughed,
  td.inline-entity-form-gin_pic_news_pic-field_img_news_before_after {
    max-width: 75px; } }

// video source
.field-name-field-inline-video-source {
  td.inline-entity-form-gin_vid_news_vid-field_news_time_code {
    width: 100px;
    text-align: center; }
  td.inline-entity-form-gin_vid_news_vid-field_vid_news_original,
  td.inline-entity-form-gin_vid_news_vid-field_vid_news_retouched {
    max-width: 115px;
    video {
      width: 100%;
      height: 100%;
      min-width: 172px; } } }



/*
 * Footer
 * ************************************************************************************************ */

// footer menu
.region-footer-menu {
  ul.menu {
    li {
      display: inline-block;
      padding-right: 10px;
      a {
        color: white;
        padding: 0;
        padding-right: 10px;
        &:last-child {
          padding-right: 0; } } } } }
.copyright_footer {
  color: white;
  font-size: 12px;
  text-transform: uppercase; }

// masquerade
.block-masquerade {
  margin-top: 50px;
  .form-group {
    margin-bottom: 0; }
  .input-group {
    .form-control {
      display: initial;
      padding: 0;
      width: 100%; } }
  .input-group-addon {
    width: auto; } }

//auto delete date
.field-widget-date-text {
  .date-no-float {
    width: 100%; }
  .date-form-element-content-multiline {
    padding: 0;
    border: 0; } }

label:has(> #edit-field-new-media-type-und) {
  display: none!important; }

.keywords {
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 0 5px;
    .row {
      padding-top: 15px;
      padding-bottom: 15px;
      border-top: 1px solid #DCDCDC;
      border-bottom: 1px solid #DCDCDC;
      background: #F8F8F8; }
    .keywords_title {
      padding-top: 10px;
      color: #A1A1A1; }
    .nav {
      border: none;
      padding-left: 15px; }
    .nav-tabs > li > a,
    .nav-tabs > li > a:hover,
    .nav-tabs > li > a:focus {
      margin-right: 0;
      background: none;
      color: black;
      border-bottom: 1px solid #ddd;
      text-align: center;
      font-weight: bold;
      border-radius: 0px;
      text-transform: uppercase; }
    .nav-tabs > li.active > a,
    .nav-tabs > li.active > a:hover,
    .nav-tabs > li.active > a:focus {
      border-bottom-color: #F8F8F8; }
    .tab-content {
      .form-type-checkboxes > label { // hide checkbox's tab label ( show it manual )
        display: none; } } }

// esnd to fingerpost review page destinations listing
.fingerpost-review-destination-list {
  div.checkbox {
    label {
      width: auto !important; } } }

.view-id-payment_reminder tr.reminder-overdue-row-1 {
   background: #fa9898; }
